import { render, staticRenderFns } from "./scheduleOverviewText.vue?vue&type=template&id=2556ba71"
import script from "./scheduleOverviewText.vue?vue&type=script&lang=js"
export * from "./scheduleOverviewText.vue?vue&type=script&lang=js"
import style0 from "./scheduleOverviewText.vue?vue&type=style&index=0&id=2556ba71&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4042112146/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2556ba71')) {
      api.createRecord('2556ba71', component.options)
    } else {
      api.reload('2556ba71', component.options)
    }
    module.hot.accept("./scheduleOverviewText.vue?vue&type=template&id=2556ba71", function () {
      api.rerender('2556ba71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sites/brand/overview/menuScheduler/scheduleOverviewText.vue"
export default component.exports